import React, { useState } from 'react';
import { Calendar, Book, FileText } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../store/languageSlice';
import { useTranslation } from 'react-i18next';

const translations = {
  ja: {
    title: "あなたの日々を彩る小さな冒険",
    subtitle: "どこからか迷い込んだ小人たちが、あなたの毎日を優しく見守っています。\nスケジュールと日記を通じて、新しい物語を紡ぎ出しましょう。",
    features: [
      {
        icon: Calendar,
        title: "スケジュール管理",
        description: "Googleカレンダーと連携して、忘れずに大切な時間を過ごせるようサポートします。"
      },
      {
        icon: FileText,
        title: "日記",
        description: "日々の出来事や感情を記録。小人たちが大切な思い出を優しく包み込みます。"
      },
      {
        icon: Book,
        title: "ストーリー",
        description: "あなたのスケジュールや日記から紡ぎ出される、小人たちが綴る心温まる物語をお楽しみください。"
      }
    ],
    cta: "さあ、Googleアカウントで登録して小人たちと一緒にあなたの物語を始めましょう。",
    switchLang: "English"
  },
  en: {
    title: "A Little Adventure to Color Your Days",
    subtitle: "Little folk who have wandered in from somewhere are gently watching over your everyday life.\nLet's weave new stories through your schedule and diary.",
    features: [
      {
        icon: Calendar,
        title: "Schedule Management",
        description: "Support you in spending important time without forgetting by organizing your plans with Google Calendar."
      },
      {
        icon: FileText,
        title: "Diary",
        description: "Record daily events and emotions. The little folk gently wrap your precious memories."
      },
      {
        icon: Book,
        title: "Story",
        description: "Enjoy heartwarming tales spun by the little folk, woven from your schedule and diary."
      }
    ],
    cta: "Come, log in and start your story with the little folk.",
    switchLang: "日本語"
  }
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <Card className="h-full">
    <CardHeader>
      <CardTitle className="flex items-center text-xl">
        <Icon className="mr-2 h-6 w-6" /> {title}
      </CardTitle>
    </CardHeader>
    <CardContent>{description}</CardContent>
  </Card>
);

const LandingPage = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === 'ja' ? 'en' : 'ja';
    i18n.changeLanguage(newLang);
    dispatch(setLanguage(newLang));
  };

  const t = translations[i18n.language];

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <Button onClick={toggleLanguage} className="mb-4">{t.switchLang}</Button>
      <h1 className="text-4xl font-bold text-center mb-8">{t.title}</h1>
      <p className="text-xl text-center mb-12 whitespace-pre-line">{t.subtitle}</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {t.features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
      <p className="text-center mt-12 text-lg">{t.cta}</p>
    </div>
  );
};

export default LandingPage;