export const userSettingsFields = [
  {
    name: 'gender',
    type: 'select',
    labelKey: 'userSettings.gender',
    options: [
      { value: 'preferNotToSay', labelKey: 'userSettings.gender.preferNotToSay' },
      { value: 'male', labelKey: 'userSettings.gender.male' },
      { value: 'female', labelKey: 'userSettings.gender.female' },
      { value: 'other', labelKey: 'userSettings.gender.other' },
    ]
  },
  {
    name: 'otherInfo',
    type: 'textarea',
    labelKey: 'userSettings.otherInfo'
  }
];

export const initialUserSettings = {
  gender: '',
  otherInfo: '',
  language: '',
  timezone: 'Asia/Tokyo'
};