import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 500;
  transition: background-color 0.2s, color 0.2s;
  margin: 0 0.5rem;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  ${props => props.variant === 'active' && `
    background-color: black;
    color: white;
  `}

  ${props => props.variant === 'default' && `
    background-color: white;
    color: black;
    border: 1px solid #e0e0e0;

    &:hover {
      background-color: #f0f0f0;
    }
  `}
`;

export const Button = ({ children, className, variant = 'default', ...props }) => {
  return (
    <StyledButton className={className} variant={variant} {...props}>
      {children}
    </StyledButton>
  );
};