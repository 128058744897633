import React, { useState, useEffect } from 'react';
import { LogOut, Settings } from 'lucide-react';
import { Button } from '../components/ui/button';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, signup } from '../store/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axios';
import styled from 'styled-components';
import UserSettings from './UserSettings';
import SignupModal from './SignupModal';
import { useTranslation } from 'react-i18next';

const HeaderContainer = styled.header`
  background-color: var(--primary);
  color: var(--primary-foreground);
  padding: 1rem;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.span`
  margin-right: 1rem;
`;

const MainContent = styled.main`
  padding-top: 2rem;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 1rem;
`;

const LogoText = styled(Link)`
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--primary-foreground);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  cursor: pointer;
`;

const Header = ({ setIsLoggedIn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();

  const googleSignup = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleResponse(tokenResponse, true),
    onError: () => setError(t('errors.GOOGLE_AUTH_FAILED')),
    flow: 'auth-code',
    scope: 'email profile https://www.googleapis.com/auth/calendar.readonly openid'
  });

  const googleSignin = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleResponse(tokenResponse, false),
    onError: () => setError(t('errors.OAUTH2_ERROR')),
    flow: 'auth-code',
    scope: 'email profile https://www.googleapis.com/auth/calendar.readonly openid',
    prompt: 'none',
    access_type: 'offline',
  });
  const clearError = () => {
    setError(null);
  };

  const handleGoogleResponse = async (tokenResponse, isSignup) => {
    try {
      const res = await axiosInstance.post('/google-login/', {
        code: tokenResponse.code,
        is_signup: isSignup
      });
      if (res.data.is_new_user) {
        dispatch(signup({ temp_token: res.data.temp_token }));
        setIsSignupModalOpen(true);
        clearError();
      } else {
        dispatch(login(res.data));
        navigate('/'); // Navigate to root path after login
        clearError();
      }
    } catch (error) {
      console.error('認証エラー:', error);
      if (error.response && error.response.data && error.response.data.error_code) {
        setError(t(`errors.${error.response.data.error_code}`));
      } else {
        setError(t('errors.INTERNAL_SERVER_ERROR'));
      }
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    clearError();
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        clearError();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    console.log('Current language:', i18n.language);
    console.log('Signup button text:', t('signupWithGoogle'));
    console.log('Signin button text:', t('signinWithGoogle'));
  }, [i18n.language]);

  useEffect(() => {
    // Google Analyticsのページビューを送信
    if (window.gtag) {
      window.gtag('config', 'G-YLKM27DBE6', {
        page_path: window.location.pathname,
      });
    }
  }, []);

  return (
    <>
      <HeaderContainer>
        <HeaderContent>
          <LogoText to="/"> {t('mySchedule')}</LogoText>
          <RightSection>
            {isLoggedIn ? (
              <>
                <UserName>{user?.name || user?.username || user?.email}</UserName>
                <Button
                  onClick={() => setIsSettingsOpen(true)}
                  className="bg-white text-[#1a73e8] border border-[#1a73e8] hover:bg-[#1a73e8] hover:text-white transition-colors duration-300 p-2 rounded-md font-roboto mr-2"
                >
                  <Settings className="h-4 w-4" />
                </Button>
                <Button
                  onClick={handleLogout}
                  className="bg-white text-[#1a73e8] border border-[#1a73e8] hover:bg-[#1a73e8] hover:text-white transition-colors duration-300 px-4 py-2 rounded-md font-roboto text-sm"
                >
                  <LogOut className="mr-2 h-4 w-4" /> {t('logout')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => googleSignup()}
                  className="bg-white text-[#1a73e8] border border-[#1a73e8] hover:bg-[#1a73e8] hover:text-white transition-colors duration-300 px-4 py-2 rounded-md font-roboto text-sm"
                >
                  {t('signupWithGoogle')}
                </Button>
                <div style={{ width: '10px' }}></div>
                <Button
                  onClick={() => googleSignin()}
                  className="bg-white text-[#1a73e8] border border-[#1a73e8] hover:bg-[#1a73e8] hover:text-white transition-colors duration-300 px-4 py-2 rounded-md font-roboto text-sm"
                >
                  {t('signinWithGoogle')}
                </Button>
              </>
            )}
          </RightSection>
        </HeaderContent>
      </HeaderContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <MainContent>
        {/* ここにDiary、HistoryStoryなどのコンテンツがります */}
      </MainContent>
      <UserSettings isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
      <SignupModal 
        isOpen={isSignupModalOpen} 
        onClose={() => setIsSignupModalOpen(false)} 
        initialLanguage={i18n.language} 
      />
    </>
  );
};

export default Header;