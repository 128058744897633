import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import diaryReducer from './diarySlice';
import storyReducer from './storySlice';
import dateReducer from './dateSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    diary: diaryReducer,
    story: storyReducer,
    date: dateReducer,
  },
});

export default store;