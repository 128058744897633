import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Button } from './ui/button';
import { ArrowLeft } from 'lucide-react';

const LegalPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [content, setContent] = useState('');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      let newFileName;
      try {
        if (location.pathname === '/privacy-policy') {
          newFileName = 'privacyPolicy';
        } else if (location.pathname === '/terms-of-service') {
          newFileName = 'termsOfService';
        } else {
          newFileName = 'unknown';
        }
        setFileName(newFileName);
        const response = await fetch(`/locales/${i18n.language}/${newFileName}.md`);
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error(`Failed to fetch ${newFileName}:`, error);
      }
    };

    fetchContent();
  }, [location.pathname, i18n.language]);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'ja' ? 'en' : 'ja';
    i18n.changeLanguage(newLang);
  };

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="container mx-auto p-4 relative">
      <div className="flex justify-between items-center mb-4">
        <Button
          onClick={handleGoBack}
          className="flex items-center text-gray-600 hover:text-gray-900 transition-colors duration-300 bg-transparent border-none"
        >
          <ArrowLeft className="mr-2" size={20} />
          {t('backToTop')}
        </Button>
        <Button
          onClick={toggleLanguage}
          className="bg-white text-gray-600 border border-gray-300 hover:bg-gray-100 hover:text-gray-900 transition-colors duration-300 px-4 py-2 rounded-md font-roboto text-sm"
        >
          {i18n.language === 'ja' ? 'English' : '日本語'}
        </Button>
      </div>
      <h1 className="text-2xl font-bold mb-4">{t(fileName)}</h1>
      <div className="prose prose-sm max-w-none">
        {content ? <ReactMarkdown>{content}</ReactMarkdown> : <p>{t('loading')}</p>}
      </div>
    </div>
  );
};

export default LegalPage;