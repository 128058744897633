import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

const dotAnimation = keyframes`
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1.0); }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
  background-color: #3b82f6;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  animation: ${dotAnimation} 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

const LoadingText = styled.p`
  margin-right: 10px;
  font-size: 16px;
  color: #4b5563;
`;

const LoadingDots = ({ text }) => {
  const { t } = useTranslation();

  return (
    <DotsContainer>
      <LoadingText>{text || t('generatingStory')}</LoadingText>
      <Dot />
      <Dot />
      <Dot />
    </DotsContainer>
  );
};

export default LoadingDots;