import React, { useState, useEffect } from 'react';
import { Calendar, FileText, Book } from 'lucide-react';
import { Button } from '../components/ui/button';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDiaries } from '../store/diarySlice';
import { checkAuthStatus, handleAuthError } from '../store/authSlice';
import styled from 'styled-components';
import CalendarDiaryIntegration from './CalendarDiaryIntegration';
import DiaryList from './DiaryList';
import StoryList from './StoryList';
import LandingPage from './LandingPage';
import { useTranslation } from 'react-i18next';

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const ContentContainer = styled.div`
  // 必要に応じてコンテンツのスタイリングを追加
`;

const ScheduleApp = () => {
  const [activeTab, setActiveTab] = useState('schedule');
  const isLoggedIn = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchDiaries()).catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch(handleAuthError());
        }
      });
      dispatch(checkAuthStatus()).catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch(handleAuthError());
        }
      });
    }
  }, [isLoggedIn, dispatch]);

  const renderContent = () => {
    if (!isLoggedIn) {
      return <LandingPage />;
    }

    switch (activeTab) {
      case 'schedule':
        return <CalendarDiaryIntegration />;
      case 'diary':
        return <DiaryList />;
      case 'story':
        return <StoryList />;
      default:
        return <CalendarDiaryIntegration />;
    }
  };

  const menuItems = [
    { id: 'schedule', icon: Calendar, label: t('schedule') },
    { id: 'diary', icon: FileText, label: t('diary') },
    { id: 'story', icon: Book, label: t('story') },
  ];

  return (
    <main className="flex-grow container mx-auto p-6">
      <div className="max-w-4xl mx-auto">
        {isLoggedIn && (
          <ButtonContainer>
            {menuItems.map((item) => (
              <Button
                key={item.id}
                onClick={() => {
                  setActiveTab(item.id);
                  window.history.pushState(null, '', `/${item.id === 'schedule' ? '' : item.id}`);
                }}
                variant={activeTab === item.id ? 'active' : 'default'}
                className="px-4 py-2 text-lg"
              >
                <item.icon className="mr-2 h-5 w-5" />
                {item.label}
              </Button>
            ))}
          </ButtonContainer>
        )}
        <ContentContainer>
          {renderContent()}
        </ContentContainer>
      </div>
    </main>
  );
};

export default ScheduleApp;