import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-white border-t border-gray-200 p-4 mt-auto">
      <div className="container mx-auto flex justify-between items-center">
        <p className="text-gray-600">&copy; 2024 My Fable Diary</p>
        <nav>
          <ul className="flex space-x-4">
            <li>
              <Link 
                to="/privacy-policy" 
                className="text-gray-800 hover:text-gray-600 underline"
              >
                {t('privacyPolicy')}
              </Link>
            </li>
            <li>
              <Link 
                to="/terms-of-service" 
                className="text-gray-800 hover:text-gray-600 underline"
              >
                {t('termsOfService')}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;