import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';
import moment from 'moment-timezone';

const getUserTimezone = () => {
  return localStorage.getItem('userTimezone') || 'Asia/Tokyo';
};

const getLocalDate = (date) => {
  const userTimezone = getUserTimezone();
  return moment.tz(date, userTimezone).format('YYYY-MM-DD');
};

export const fetchDiaries = createAsyncThunk(
  'diary/fetchDiaries',
  async () => {
    console.log('Fetching diaries...');
    const response = await axiosInstance.get('diaries/');
    console.log('Diaries fetched:', response.data);
    return response.data;
  }
);

export const createDiary = createAsyncThunk(
  'diary/createDiary',
  async (diary, { rejectWithValue }) => {
    try {
      const localDate = getLocalDate(diary.date);
      const response = await axiosInstance.post('/diaries/', { ...diary, date: localDate });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDiaryByDate = createAsyncThunk(
  'diary/fetchByDate',
  async (date, { rejectWithValue }) => {
    try {
      const localDate = getLocalDate(date);
      const response = await axiosInstance.get(`/diaries/get_diary_by_date/?date=${localDate}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const diarySlice = createSlice({
  name: 'diary',
  initialState: {
    diaries: [],
    currentDiary: null,
    status: 'idle',
    error: null
  },
  reducers: {
    setDiaries: (state, action) => {
      state.diaries = action.payload;
      state.status = 'succeeded';
    },
    setLoadingState: (state) => {
      state.status = 'loading';
    },
    setError: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    resetStore: (state) => {
      state.diaries = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiaries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDiaries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.diaries = action.payload;
      })
      .addCase(fetchDiaries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createDiary.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createDiary.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.diaries.push(action.payload);
      })
      .addCase(createDiary.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchDiaryByDate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDiaryByDate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentDiary = action.payload;
      })
      .addCase(fetchDiaryByDate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.currentDiary = null;
      });
  }
});

export const { setDiaries, setLoadingState, setError, resetStore } = diarySlice.actions;

export default diarySlice.reducer;