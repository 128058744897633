import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStories } from '../store/storySlice';
import { handleAuthError } from '../store/authSlice';
import styled from 'styled-components';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { useTranslation } from 'react-i18next';

const StoryListContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StoryItem = styled(Card)`
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StoryDate = styled.h3`
  font-size: 1.2rem;
  color: #4a5568;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 0.5rem;
`;

const StoryContent = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #2d3748;
`;

const EmptyMessage = styled.p`
  text-align: center;
  color: #718096;
  font-style: italic;
`;

const StoryList = () => {
  const dispatch = useDispatch();
  const { status, stories, error } = useSelector((state) => state.story);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(fetchStories()).catch((error) => {
      if (error.response && error.response.status === 401) {
        dispatch(handleAuthError());
      }
    });
  }, [dispatch]);

  if (status === 'loading') {
    return <StoryListContainer><EmptyMessage>{t('loading')}</EmptyMessage></StoryListContainer>;
  }

  if (status === 'failed') {
    return (
      <StoryListContainer>
        <EmptyMessage className="text-red-500">
          {typeof error === 'string' ? error : t('storyFetchFailed')}
        </EmptyMessage>
      </StoryListContainer>
    );
  }

  // 今日の日付を取得
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // 過去7日間の物語をフィルタリング
  const recentStories = stories.filter(story => {
    const storyDate = new Date(story.date);
    const diffTime = Math.abs(today - storyDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 7;
  });

  // 日付でソート（降順）
  recentStories.sort((a, b) => new Date(b.date) - new Date(a.date));

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      weekday: 'short'
    });
  };

  return (
    <StoryListContainer>
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-serif text-center">{t('recentStories')}</CardTitle>
        </CardHeader>
        <CardContent>
          {recentStories.length > 0 ? (
            recentStories.map((story) => (
              <StoryItem key={story.id} data-testid={`story-${story.id}`}>
                <CardContent>
                  <StoryDate>{formatDate(story.date)}</StoryDate>
                  <StoryContent>{story.content}</StoryContent>
                </CardContent>
              </StoryItem>
            ))
          ) : (
            <EmptyMessage>{t('noStoriesFound')}</EmptyMessage>
          )}
        </CardContent>
      </Card>
    </StoryListContainer>
  );
};

export default StoryList;