import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDiary, fetchDiaryByDate } from '../store/diarySlice';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const DiaryContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
`;

const DiaryContent = styled.textarea`
  font-size: 1.125rem;
  line-height: 1.75;
  font-family: serif;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  width: 95%;
  resize: vertical;
  border: 1px solid #e2e8f0;
  transition: max-height 0.3s ease-out;
  overflow-y: auto;
  max-height: ${props => props.isOpen ? '500px' : '2.5rem'};
  cursor: ${props => props.isOpen ? 'auto' : 'pointer'};
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const TitleButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const DiaryForm = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { currentDiary, status } = useSelector((state) => state.diary);
  const selectedDate = useSelector((state) => state.date.selectedDate);
  const [content, setContent] = useState('');
  const [localDiary, setLocalDiary] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchDiaryByDate(selectedDate));
    setContent('');
  }, [dispatch, selectedDate]);

  useEffect(() => {
    if (currentDiary && currentDiary.exists !== false) {
      setContent(currentDiary.content || '');
      setLocalDiary(currentDiary);
    } else {
      setContent('');
      setLocalDiary(null);
    }
  }, [currentDiary]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(createDiary({ content, date: selectedDate }));
    if (createDiary.fulfilled.match(result)) {
      setContent(result.payload.content);
      setLocalDiary(result.payload);
    }
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleContentClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  function formatJapaneseDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('ja-JP', { month: 'long', day: 'numeric' });
  }

  return (
    <DiaryContainer>
      <Card className="h-full flex flex-col mt-2">
        <CardHeader className="h-16 flex items-center">
          <LabelWrapper className="w-full">
            <TitleButtonWrapper>
              <CardTitle className="text-2xl font-serif">
                {t('diary')}
              </CardTitle>
              <Button onClick={handleSubmit} disabled={status === 'loading'}>
                {currentDiary && currentDiary.exists !== false ? t('update') : t('register')}
              </Button>
            </TitleButtonWrapper>
            <ToggleButton onClick={toggleAccordion}>
              {isOpen ? '▲' : '▼'}
            </ToggleButton>
          </LabelWrapper>
        </CardHeader>
        <CardContent className="flex-grow flex flex-col">
          {status === 'loading' && <p className="text-center text-gray-500">{t('loading')}</p>}
          <DiaryContent
            isOpen={isOpen}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder={t('writeDiaryHere')}
            onClick={handleContentClick}
            readOnly={!isOpen}
          />
        </CardContent>
      </Card>
    </DiaryContainer>
  );
};

export default DiaryForm;