import React, { useState } from 'react';
import styled from 'styled-components';
import Story from './Story';
import DiaryForm from './DiaryForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const StorySection = styled.div`
  flex: 1;
  width: 100%;
`;

const DiarySection = styled.div`
  flex: 1;
  width: 100%;
`;

const DiaryStoryIntegration = () => {
  const [isStoryOpen, setIsStoryOpen] = useState(false);
  const [isDiaryOpen, setIsDiaryOpen] = useState(false);

  return (
    <Container>
      <StorySection>
        <Story isOpen={isStoryOpen} setIsOpen={setIsStoryOpen} />
      </StorySection>
      <DiarySection>
        <DiaryForm isOpen={isDiaryOpen} setIsOpen={setIsDiaryOpen} />
      </DiarySection>
    </Container>
  );
};

export default DiaryStoryIntegration;