import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Header from './components/Header';
import ScheduleApp from './components/ScheduleApp';
import Footer from './components/Footer';
import LegalModal from './components/LegalModal';
import LegalPage from './components/LegalPage';
import { updateLanguage } from './i18n';
import { useTranslation } from 'react-i18next';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [legalModalContent, setLegalModalContent] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    updateLanguage();
  }, []);

  const fetchLegalContent = async (type) => {
    try {
      const response = await fetch(`/locales/${i18n.language}/${type}.md`);
      const content = await response.text();
      setLegalModalContent({ content, title: type });
    } catch (error) {
      console.error(`Failed to fetch ${type}:`, error);
    }
  };

  const handleOpenPrivacyPolicy = () => fetchLegalContent('privacyPolicy');
  const handleOpenTermsOfService = () => fetchLegalContent('termsOfService');
  const handleCloseLegalModal = () => setLegalModalContent(null);

  return (
    <Router>
      <GoogleOAuthProvider clientId="11381413275-dctfus3hspsk59cfvhsblt5mgbf96ssf.apps.googleusercontent.com">
        <div className="flex flex-col min-h-screen">
          <Header setIsLoggedIn={setIsLoggedIn} />
          <Routes>
            <Route path="/" element={<ScheduleApp />} />
            <Route path="/privacy-policy" element={<LegalPage />} />
            <Route path="/terms-of-service" element={<LegalPage />} />
          </Routes>
          <Footer 
            onOpenPrivacyPolicy={handleOpenPrivacyPolicy}
            onOpenTermsOfService={handleOpenTermsOfService}
          />
          {legalModalContent && (
            <LegalModal
              isOpen={!!legalModalContent}
              onClose={handleCloseLegalModal}
              content={legalModalContent.content}
              title={legalModalContent.title}
            />
          )}
        </div>
      </GoogleOAuthProvider>
    </Router>
  );
};

export default App;