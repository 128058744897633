import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

const getUserTimezone = () => {
  // ユーザー設定からタイムゾーンを取得する関数
  // 実際の実装では、ユーザー設定を保存している場所から取得してください
  return localStorage.getItem('userTimezone') || 'Asia/Tokyo';
};

const dateSlice = createSlice({
  name: 'date',
  initialState: {
    selectedDate: moment().tz(getUserTimezone()).format('YYYY-MM-DD'),
  },
  reducers: {
    setSelectedDate: (state, action) => {
      const userTz = getUserTimezone();
      const date = moment.tz(action.payload, userTz);
      state.selectedDate = date.format('YYYY-MM-DD');
      console.log(`日付が設定されました: ${state.selectedDate}`);
      console.log(`ユーザータイムゾーン: ${userTz}`);
      console.log(`ローカル時間: ${date.format('YYYY-MM-DD HH:mm:ss')}`);
      console.log(`UTC時間: ${date.utc().format('YYYY-MM-DD HH:mm:ss')}`);
    },
    setYesterday: (state) => {
      const userTz = getUserTimezone();
      const date = moment.tz(state.selectedDate, userTz).subtract(1, 'days');
      state.selectedDate = date.format('YYYY-MM-DD');
      console.log(`昨日の日付に設定されました: ${state.selectedDate}`);
      console.log(`ユーザータイムゾーン: ${userTz}`);
      console.log(`ローカル時間: ${date.format('YYYY-MM-DD HH:mm:ss')}`);
      console.log(`UTC時間: ${date.utc().format('YYYY-MM-DD HH:mm:ss')}`);
    },
    setToday: (state) => {
      const userTz = getUserTimezone();
      const date = moment().tz(userTz);
      state.selectedDate = date.format('YYYY-MM-DD');
      console.log(`今日の日付に設定されました: ${state.selectedDate}`);
      console.log(`ユーザータイムゾーン: ${userTz}`);
      console.log(`ローカル時間: ${date.format('YYYY-MM-DD HH:mm:ss')}`);
      console.log(`UTC時間: ${date.utc().format('YYYY-MM-DD HH:mm:ss')}`);
    },
    setTomorrow: (state) => {
      const userTz = getUserTimezone();
      const date = moment.tz(state.selectedDate, userTz).add(1, 'days');
      state.selectedDate = date.format('YYYY-MM-DD');
      console.log(`明日の日付に設定されました: ${state.selectedDate}`);
      console.log(`ユーザータイムゾーン: ${userTz}`);
      console.log(`ローカル時間: ${date.format('YYYY-MM-DD HH:mm:ss')}`);
      console.log(`UTC時間: ${date.utc().format('YYYY-MM-DD HH:mm:ss')}`);
    },
  },
});

export const { setSelectedDate, setYesterday, setToday, setTomorrow } = dateSlice.actions;

export default dateSlice.reducer;