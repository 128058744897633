import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { completeSignup } from '../store/authSlice';
import { fetchStory } from '../store/storySlice';
import axiosInstance from '../api/axios';
import styled from 'styled-components';
import { storySettingsFields, initialStorySettings } from '../constants/storySettingsFields';
import { userSettingsFields, initialUserSettings } from '../constants/userSettingsFields';
import LoadingDots from './LoadingDots';
import {
  Modal,
  ModalContent,
  Title,
  Form,
  FormGroup,
  Label,
  Input,
  Select,
  Button,
  ErrorMessage
} from './common/FormStyles';
import { Textarea } from './ui/textarea';
import SignupCalendarSelection from './SignupCalendarSelection';

const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const StepDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#3b82f6' : '#d1d5db'};
  margin: 0 5px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: sticky;
  bottom: 0;
  background-color: white;
  padding-top: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalContentWrapper = styled.div`
  position: relative;
  padding-top: 40px;
`;

const ConfirmationSection = styled.div`
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
`;

const ConfirmationTitle = styled.h3`
  font-size: 1.2rem;
  color: #374151;
  margin-bottom: 12px;
`;

const ConfirmationItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px 0;
  border-bottom: 1px solid #e5e7eb;

  &:last-child {
    border-bottom: none;
  }
`;

const ItemLabel = styled.span`
  font-weight: 500;
  color: #4b5563;
`;

const ItemValue = styled.span`
  color: #1f2937;
`;

const StyledSignupModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const StyledSignupModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  max-height: 80vh;
  overflow: hidden;
  z-index: 1001;
`;

const ScrollableContent = styled.div`
  max-height: calc(80vh - 300px);
  overflow-y: scroll !important;
  padding-bottom: 20px;
`;

const FixedConfirmationMessage = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1002;
  padding: 1rem 0;
  text-align: center;
  font-weight: bold;
`;

const ConfirmationPage = styled.div`
  white-space: pre-wrap;
`;

const SignupModal = ({ isOpen, onClose, initialLanguage }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const tempToken = useSelector((state) => state.auth.tempToken);
  const [step, setStep] = useState(1);
  const [storySettings, setStorySettings] = useState(initialStorySettings);
  const [userSettings, setUserSettings] = useState({
    ...initialUserSettings,
    language: initialLanguage || 'ja',
    timezone: initialLanguage === 'en' ? 'America/New_York' : 'Asia/Tokyo'
  });
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Changing language to:', userSettings.language);
    i18n.changeLanguage(userSettings.language);
  }, [userSettings.language, i18n]);

  const handleStorySettingsChange = (e) => {
    setStorySettings({ ...storySettings, [e.target.name]: e.target.value });
  };

  const handleUserSettingsChange = (e) => {
    setUserSettings({ ...userSettings, [e.target.name]: e.target.value });
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    const newTimezone = newLanguage === 'en' ? 'America/New_York' : 'Asia/Tokyo';
    setUserSettings({ ...userSettings, language: newLanguage, timezone: newTimezone });
    i18n.changeLanguage(newLanguage);
  };

  const handleBack = () => {
    setStep(prevStep => prevStep - 1);
  };

  const handleNext = () => {
    setStep(prevStep => prevStep + 1);
  };

  const handleCalendarSelect = (calendarIds) => {
    setSelectedCalendars(calendarIds);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const dataToSend = {
        temp_token: tempToken,
        user_settings: {
          ...userSettings,
          ...storySettings,
          language: userSettings.language
        },
        selected_calendars: selectedCalendars
      };
      console.log('送信データ:', JSON.stringify(dataToSend, null, 2));
      const response = await axiosInstance.post('/complete-signup/', dataToSend);
      console.log('レスポンス:', response.data);
      dispatch(completeSignup(response.data));
      
      // サインアップ完了後に少し待ってからプローグを取得
      setTimeout(() => {
        const localDate = new Date().toISOString().split('T')[0];
        dispatch(fetchStory(localDate));
      }, 2000); // 2秒待機

      onClose();
    } catch (err) {
      console.error('エラーレスポンス:', err.response?.data);
      setError(err.response?.data?.error || t('signupError'));
    } finally {
      setIsLoading(false);
    }
  };

  const getDisplayValue = (fieldName, value) => {
    if (fieldName === 'language') {
      return t(`languages.${value === 'en' ? 'english' : 'japanese'}`);
    }
    if (fieldName === 'timezone') {
      return value === 'America/New_York' ? t('timezones.newYork') : t('timezones.tokyo');
    }
    const field = [...storySettingsFields, ...userSettingsFields].find(f => f.name === fieldName);
    if (field && field.type === 'select') {
      const option = field.options.find(o => o.value === value);
      return option ? t(option.labelKey) : value;
    }
    return value;
  };

  const renderConfirmationSection = (title, settings) => (
    <ConfirmationSection>      <ConfirmationTitle>{t(`${title}.title`)}</ConfirmationTitle>
      {Object.entries(settings).map(([key, value]) => (

        <ConfirmationItem key={key}>
          <ItemLabel>{t(`${title}.${key}`)}</ItemLabel>
          <ItemValue>{getDisplayValue(key, value)}</ItemValue>
        </ConfirmationItem>
      ))}
    </ConfirmationSection>
  );

  const renderCalendarConfirmationSection = () => (
    <ConfirmationSection>
      <ConfirmationTitle>{t('calendarSettings.title')}</ConfirmationTitle>
      {selectedCalendars.map(calendar => (
        <ConfirmationItem key={calendar.id}>
          <ItemLabel>{t('calendarSettings.calendarName')}</ItemLabel>
          <ItemValue>{calendar.summary}</ItemValue>
        </ConfirmationItem>
      ))}
    </ConfirmationSection>
  );

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Title>{t('signupModal.languageAndTimezone')}</Title>
            <Form onSubmit={(e) => { e.preventDefault(); setStep(2); }}>
              <FormGroup>
                <Label htmlFor="language">{t('signupModal.selectLanguage')}</Label>
                <Select
                  id="language"
                  name="language"
                  value={userSettings.language}
                  onChange={handleLanguageChange}
                >
                  <option value="en">{t('languages.english')}</option>
                  <option value="ja">{t('languages.japanese')}</option>
                </Select>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="timezone">{t('signupModal.selectTimezone')}</Label>
                <Select
                  id="timezone"
                  name="timezone"
                  value={userSettings.timezone}
                  onChange={handleUserSettingsChange}
                >
                  <option value="America/New_York" data-label-key="timezones.newYork">{t('timezones.newYork')}</option>
                  <option value="Asia/Tokyo" data-label-key="timezones.tokyo">{t('timezones.tokyo')}</option>
                </Select>
              </FormGroup>
              <ButtonContainer>
                <Button type="submit">{t('common.next')}</Button>
              </ButtonContainer>
            </Form>
          </>
        );
      case 2:
        return (
          <>
            <Title>{t('storySettings.title')}</Title>
            <p>{t('signupModal.storySettingsDescription')}</p>
            <Form onSubmit={(e) => { e.preventDefault(); setStep(3); }}>
              {storySettingsFields.map((field) => (
                <FormGroup key={field.name}>
                  <Label htmlFor={field.name}>{t(field.labelKey)}</Label>
                  <Input
                    id={field.name}
                    name={field.name}
                    type={field.type}
                    value={storySettings[field.name]}
                    onChange={handleStorySettingsChange}
                    maxLength={20}  // ここに入力制限を追加
                  />
                </FormGroup>
              ))}
              <NavigationButtons>
                <Button type="button" onClick={handleBack}>{t('common.back')}</Button>
                <Button type="submit">{t('common.next')}</Button>
              </NavigationButtons>
            </Form>
          </>
        );
      case 3:
        return (
          <>
            <Title>{t('userSettings.title')}</Title>
            <p>{t('signupModal.userSettingsDescription')}</p>
            <Form onSubmit={(e) => { e.preventDefault(); setStep(4); }}>
              {userSettingsFields.map((field) => (
                <FormGroup key={field.name}>
                  <Label htmlFor={field.name}>{t(field.labelKey)}</Label>
                  {field.type === 'select' ? (
                    <Select
                      id={field.name}
                      name={field.name}
                      value={userSettings[field.name]}
                      onChange={handleUserSettingsChange}
                    >
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {t(option.labelKey)}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Textarea
                      id={field.name}
                      name={field.name}
                      value={userSettings[field.name]}
                      onChange={handleUserSettingsChange}
                      rows={4}
                      placeholder={t('userSettings.otherInfoPlaceholder')}
                      maxLength={100}  // ここに入力制限を追加
                    />
                  )}
                </FormGroup>
              ))}
              <NavigationButtons>
                <Button type="button" onClick={handleBack}>{t('common.back')}</Button>
                <Button type="submit">{t('common.next')}</Button>
              </NavigationButtons>
            </Form>
          </>
        );
      case 4:
        return (
          <>
            <Title>{t('calendarSettings.title')}</Title>
            <p>{t('signupModal.calendarSettingsDescription')}</p>
            <SignupCalendarSelection
              tempToken={tempToken}
              onCalendarSelect={handleCalendarSelect}
            />
            <NavigationButtons>
              <Button type="button" onClick={handleBack}>{t('common.back')}</Button>
              <Button type="button" onClick={handleNext}>{t('common.next')}</Button>
            </NavigationButtons>
          </>
        );
      case 5:
        return (
          <>
            <Title>{t('signupModal.confirmation')}</Title>
            <p>{t('signupModal.confirmationDescription')}</p>
            <ScrollableContent>
              {renderConfirmationSection('storySettings', storySettings)}
              {renderConfirmationSection('userSettings', {
                ...userSettings,
                language: userSettings.language,
                timezone: userSettings.timezone
              })}
              {renderCalendarConfirmationSection()}
            </ScrollableContent>
            {isLoading && <LoadingDots />}
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <NavigationButtons>
              <Button type="button" onClick={handleBack} disabled={isLoading}>{t('common.back')}</Button>
              <Button type="button" onClick={handleSubmit} disabled={isLoading}>
                {t('common.start')}
              </Button>
            </NavigationButtons>
          </>
        );
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <StyledSignupModal>
      <StyledSignupModalContent>
        <ModalContentWrapper>
          <CloseButton onClick={onClose}>×</CloseButton>
          <StepIndicator>
            <StepDot active={step >= 1} />
            <StepDot active={step >= 2} />
            <StepDot active={step >= 3} />
            <StepDot active={step >= 4} />
            <StepDot active={step >= 5} />
          </StepIndicator>
          {renderStepContent()}
        </ModalContentWrapper>
      </StyledSignupModalContent>
    </StyledSignupModal>
  );
};

export default SignupModal;