import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../api/axios';

const SignupCalendarSelection = ({ tempToken, onCalendarSelect }) => {
  const { t } = useTranslation();
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCalendars();
  }, []);

  const fetchCalendars = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post('/calendars/list_calendars/', { temp_token: tempToken });
      setCalendars(response.data);
    } catch (error) {
      console.error('カレンダーリストの取得に失敗しました:', error);
      setError(error.response?.data?.error || t('calendarListFetchFailed'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCalendarSelection = (calendarId, calendarSummary) => {
    setSelectedCalendars(prev => ({
      ...prev,
      [calendarId]: !prev[calendarId]
    }));
  };

  useEffect(() => {
    const selectedCalendarInfo = Object.keys(selectedCalendars)
      .filter(id => selectedCalendars[id])
      .map(id => ({
        id: id,
        summary: calendars.find(cal => cal.id === id)?.summary || ''
      }));
    onCalendarSelect(selectedCalendarInfo);
  }, [selectedCalendars, onCalendarSelect, calendars]);

  if (isLoading) return <p>{t('loading')}</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <ul>
        {calendars.map(calendar => (
          <li key={calendar.id}>
            <label>
              <input
                type="checkbox"
                checked={selectedCalendars[calendar.id] || false}
                onChange={() => handleCalendarSelection(calendar.id, calendar.summary)}
              />
              {calendar.summary}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SignupCalendarSelection;