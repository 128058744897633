import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axios';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Modal,
  ModalContent,
  Title,
  Form,
  FormGroup,
  Label,
  Button,
  ButtonGroup,
  CancelButton,
  SaveButton,
  ErrorMessage
} from './common/FormStyles';

// New style components added
const CalendarList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const CalendarItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  input[type="checkbox"] {
    margin-right: 0.5rem;
  }

  label {
    cursor: pointer;
  }
`;

const SectionWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
`;

const ActionButton = styled(Button)`
  margin-top: 0.5rem;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  &:hover {
    background-color: #e8e8e8;
  }
`;

const CalendarSettings = ({
  isOpen,
  onClose,
  connectedCalendars,
  availableCalendars,
  onConnectCalendars,
  onDisconnectCalendars,
  handleCalendarSelection,
  handleConnectedCalendarSelection,
  selectedCalendars,
  selectedConnectedCalendars,
}) => {
  const { t } = useTranslation();
  const [selectedAvailableCalendars, setSelectedAvailableCalendars] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      resetSelections();
    }
  }, [isOpen, connectedCalendars, availableCalendars]);

  const resetSelections = () => {
    setSelectedAvailableCalendars({});
  };

  const handleAvailableCalendarChange = (e, calendarId) => {
    setSelectedAvailableCalendars(prev => ({
      ...prev,
      [calendarId]: e.target.checked
    }));
  };

  const handleDisconnect = async () => {
    setIsLoading(true);
    try {
      const calendarsToDisconnect = Object.keys(selectedConnectedCalendars).filter(id => selectedConnectedCalendars[id]);
      await onDisconnectCalendars(calendarsToDisconnect);
      resetSelections();
    } catch (err) {
      setError(t('calendarDisconnectionFailed'));
      console.error('カレンダー接続解除エラー:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnect = async () => {
    setIsLoading(true);
    try {
      const selectedCalendarIds = Object.keys(selectedCalendars).filter(id => selectedCalendars[id]);
      await onConnectCalendars(selectedCalendarIds);
      resetSelections();
    } catch (err) {
      setError(t('calendarConnectionFailed'));
      console.error('カレンダー接続エラー:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  if (isLoading) {
    return <Modal><ModalContent>{t('loading')}</ModalContent></Modal>;
  }

  return (
    <Modal>
      <ModalContent>
        <Title>{t('calendarSelection')}</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Form>
          <SectionWrapper>
            <SectionTitle>{t('connectedCalendars')}</SectionTitle>
            <CalendarList>
              {connectedCalendars.map(calendar => (
                <CalendarItem key={calendar.id}>
                  <input
                    type="checkbox"
                    id={`connected-${calendar.id}`}
                    checked={selectedConnectedCalendars[calendar.id] || false}
                    onChange={(e) => handleConnectedCalendarSelection(calendar.id, e.target.checked)}
                  />
                  <label htmlFor={`connected-${calendar.id}`}>{calendar.name}</label>
                </CalendarItem>
              ))}
            </CalendarList>
            <ActionButton 
              onClick={handleDisconnect} 
              disabled={!Object.values(selectedConnectedCalendars).some(Boolean)}
            >
              {t('disconnectSelectedCalendars')}
            </ActionButton>
          </SectionWrapper>
          
          <SectionWrapper>
            <SectionTitle>{t('availableCalendars')}</SectionTitle>
            {availableCalendars.length > 0 ? (
              <>
                <CalendarList>
                  {availableCalendars.map(calendar => (
                    <CalendarItem key={calendar.id}>
                      <input
                        type="checkbox"
                        id={`available-${calendar.id}`}
                        checked={selectedCalendars[calendar.id] || false}
                        onChange={(e) => handleCalendarSelection(calendar.id, e.target.checked)}
                      />
                      <label htmlFor={`available-${calendar.id}`}>{calendar.summary}</label>
                    </CalendarItem>
                  ))}
                </CalendarList>
                <ActionButton 
                  onClick={handleConnect} 
                  disabled={!Object.values(selectedCalendars).some(Boolean)}
                >
                  {t('connectSelectedCalendars')}
                </ActionButton>
              </>
            ) : (
              <p>{t('noAvailableCalendars')}</p>
            )}
          </SectionWrapper>
          
          <ButtonGroup>
            <CancelButton type="button" onClick={onClose}>
              {t('close')}
            </CancelButton>
          </ButtonGroup>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default CalendarSettings;