import axios from 'axios';
import { handleTokenRefresh } from '../utils/auth';

const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://api.myfablediary.com/api/'
  : 'http://localhost:8000/api/';

const instance = axios.create({
  baseURL,
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    console.log('Request config:', config);
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    console.log('Response:', response);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (await handleTokenRefresh(originalRequest)) {
        return instance(originalRequest);
      }
    }
    console.error('Response error:', error);
    return Promise.reject(error);
  }
);

export default instance;