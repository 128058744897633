import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';

export const checkAuthStatus = createAsyncThunk(
  'auth/checkStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/auth/status/');
      if (response.data.isAuthenticated) {
        return { user: response.data.user, isAuthenticated: true };
      } else {
        return { user: null, isAuthenticated: false };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const response = await axiosInstance.post('/token/refresh/', { refresh: refreshToken });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const handleAuthError = createAsyncThunk(
  'auth/handleAuthError',
  async (_, { dispatch }) => {
    dispatch(logout());
    window.location.href = '/';
  }
);

const initialState = {
  user: null,
  accessToken: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('refreshToken'),
  isAuthenticated: !!localStorage.getItem('token'),
  isNewUser: false,
  tempToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      const { access, refresh, user } = action.payload;
      state.accessToken = access;
      state.refreshToken = refresh;
      state.user = user;
      state.isAuthenticated = true;
      if (access) localStorage.setItem('token', access);
      if (refresh) localStorage.setItem('refreshToken', refresh);
      if (user.timezone) localStorage.setItem('userTimezone', user.timezone);
      if (user.language) localStorage.setItem('userLanguage', user.language);
      console.log('ログイン成功:', user);
    },
    logout(state) {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.tempToken = null;
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userTimezone');
      localStorage.removeItem('userLanguage');
      console.log('ログアウト');
    },
    signup(state, action) {
      const { temp_token } = action.payload;
      state.tempToken = temp_token;
      state.isNewUser = true;
      console.log('サインアップ開始:', temp_token);
    },
    completeSignup(state, action) {
      const { access, refresh, user } = action.payload;
      state.accessToken = access;
      state.refreshToken = refresh;
      state.user = user;
      state.isAuthenticated = true;
      state.isNewUser = false;
      state.tempToken = null;
      if (access) localStorage.setItem('token', access);
      if (refresh) localStorage.setItem('refreshToken', refresh);
      if (user.timezone) localStorage.setItem('userTimezone', user.timezone);
      if (user.language) localStorage.setItem('userLanguage', user.language);
      console.log('サインアップ完了:', user);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload.isAuthenticated;
        state.user = action.payload.user;
      })
      .addCase(checkAuthStatus.rejected, (state) => {
        state.isAuthenticated = false;
        state.user = null;
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.accessToken = action.payload.access;
        localStorage.setItem('token', action.payload.access);
      })
      .addCase(refreshToken.rejected, (state) => {
        state.user = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
      })
      .addCase(handleAuthError.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.user = null;
        state.accessToken = null;
        state.refreshToken = null;
      });
  },
});

export const { login, logout, signup, completeSignup } = authSlice.actions;

export default authSlice.reducer;