import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDiaries } from '../store/diarySlice';
import { handleAuthError } from '../store/authSlice';
import styled from 'styled-components';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { useTranslation } from 'react-i18next';

const DiaryListContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const DiaryItem = styled(Card)`
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const DiaryDate = styled.h3`
  font-size: 1.2rem;
  color: #4a5568;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 0.5rem;
`;

const DiaryContent = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #2d3748;
`;

const EmptyMessage = styled.p`
  text-align: center;
  color: #718096;
  font-style: italic;
`;

const DiaryList = () => {
  const dispatch = useDispatch();
  const { status, diaries, error } = useSelector((state) => state.diary);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(fetchDiaries()).catch((error) => {
      if (error.response && error.response.status === 401) {
        dispatch(handleAuthError());
      }
    });
  }, [dispatch]);

  if (status === 'loading') {
    return <DiaryListContainer><EmptyMessage>{t('loading')}</EmptyMessage></DiaryListContainer>;
  }

  if (status === 'failed') {
    return (
      <DiaryListContainer>
        <EmptyMessage className="text-red-500">
          {typeof error === 'string' ? error : t('diaryFetchFailed')}
        </EmptyMessage>
      </DiaryListContainer>
    );
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const recentDiaries = diaries.filter(diary => {
    const diaryDate = new Date(diary.date);
    const diffTime = Math.abs(today - diaryDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 7;
  });

  recentDiaries.sort((a, b) => new Date(b.date) - new Date(a.date));

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      weekday: 'short'
    });
  };

  return (
    <DiaryListContainer>
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-serif text-center">{t('recentDiaries')}</CardTitle>
        </CardHeader>
        <CardContent>
          {recentDiaries.length > 0 ? (
            recentDiaries.map((diary) => (
              <DiaryItem key={diary.id} data-testid={`diary-${diary.id}`}>
                <CardContent>
                  <DiaryDate>{formatDate(diary.date)}</DiaryDate>
                  <DiaryContent>{diary.content}</DiaryContent>
                </CardContent>
              </DiaryItem>
            ))
          ) : (
            <EmptyMessage>{t('noDiariesFound')}</EmptyMessage>
          )}
        </CardContent>
      </Card>
    </DiaryListContainer>
  );
};

export default DiaryList;