export const storySettingsFields = [
    {
      name: 'littlePersonPronoun',
      type: 'text',
      labelKey: 'storySettings.littlePersonPronoun'
    },
    {
      name: 'masterTitle',
      type: 'text',
      labelKey: 'storySettings.masterTitle'
    },
    {
      name: 'littlePersonPersonality',
      type: 'text',
      labelKey: 'storySettings.littlePersonPersonality'
    },
    {
      name: 'storyTaste',
      type: 'text',
      labelKey: 'storySettings.storyTaste'
    }
  ];
  
  export const initialStorySettings = {
    littlePersonPronoun: '',
    masterTitle: '',
    littlePersonPersonality: '',
    storyTaste: ''
  };