import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generateStory, fetchStory } from '../store/storySlice';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StoryContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
`;

const StoryContent = styled.div`
  font-size: 1.125rem;
  line-height: 1.75;
  font-family: serif;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  width: 95%;
  overflow-y: auto;
  transition: max-height 0.3s ease-out;
  max-height: ${props => props.isOpen ? '500px' : '2.5rem'};
  cursor: ${props => props.isOpen ? 'auto' : 'pointer'};
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const TitleButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const Story = () => {
  const dispatch = useDispatch();
  const { story, status } = useSelector((state) => state.story);
  const selectedDate = useSelector((state) => state.date.selectedDate);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    console.log('selectedDate:', selectedDate);
    dispatch(fetchStory(selectedDate)).catch(error => {
      console.error('Story fetch error:', error);
      // エラーメッセージを表示するための状態を更新
    });
  }, [dispatch, selectedDate]);

  useEffect(() => {
    console.log('story:', story);
    console.log('status:', status);
  }, [story, status]);

  const handleGenerate = () => {
    dispatch(generateStory(selectedDate));
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleContentClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  function formatJapaneseDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('ja-JP', { month: 'long', day: 'numeric' });
  }

  return (
    <StoryContainer>
      <Card className="h-full flex flex-col mt-2">
        <CardHeader className="h-16 flex items-center">
          <LabelWrapper className="w-full">
            <TitleButtonWrapper>
              <CardTitle className="text-2xl font-serif">
                {t('story')}
              </CardTitle>
              <Button onClick={handleGenerate} disabled={status === 'loading'}>
                {t('regenerateStory')}
              </Button>
            </TitleButtonWrapper>
            <ToggleButton onClick={toggleAccordion}>
              {isOpen ? '▲' : '▼'}
            </ToggleButton>
          </LabelWrapper>
        </CardHeader>
        <CardContent className="flex-grow flex flex-col">
          {status === 'loading' && <p className="text-center text-gray-500">{t('loading')}</p>}
          {status === 'succeeded' && !story && (
            <p className="text-center text-gray-500">
              {t('noStoryForDate', { date: formatJapaneseDate(selectedDate) })}
            </p>
          )}
          {story && (
            <StoryContent isOpen={isOpen} onClick={handleContentClick}>
              {story.content}
            </StoryContent>
          )}
        </CardContent>
      </Card>
    </StoryContainer>
  );
};

export default Story;