import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import GoogleCalendar from './GoogleCalendar';
import DiaryStoryIntegration from './DiaryStoryIntegration';
import { setYesterday, setToday, setTomorrow, setSelectedDate } from '../store/dateSlice';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`;

const DateControlSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const ContentSection = styled.div`
  display: flex;
  gap: 2rem;
  height: calc(100vh - 200px);

  @media (max-width: 1200px) {
    flex-direction: column;
    height: auto;
  }
`;

const CalendarSection = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  min-width: 400px;

  @media (max-width: 1200px) {
    min-width: 100%;
    margin-bottom: 2rem;
  }
`;

const DiaryStorySection = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  min-width: 500px;

  @media (max-width: 1200px) {
    min-width: 100%;
  }
`;

const CalendarDiaryIntegration = () => {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.date.selectedDate);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setToday());
    }
  }, [isAuthenticated, dispatch]);

  const handleDateChange = (date) => {
    dispatch(setSelectedDate(date.toISOString().split('T')[0]));
  };

  return (
    <Container>
      <DateControlSection>
        <Button onClick={() => dispatch(setYesterday())}>{t('previousDay')}</Button>
        <Button onClick={() => dispatch(setToday())}>
          <h3>{new Date(selectedDate).toLocaleDateString(t('locale'), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</h3>
        </Button>
        <Button onClick={() => dispatch(setTomorrow())}>{t('nextDay')}</Button>
      </DateControlSection>
      <ContentSection>
        <CalendarSection>
          <GoogleCalendar />
        </CalendarSection>
        <DiaryStorySection>
          <DiaryStoryIntegration />
        </DiaryStorySection>
      </ContentSection>
    </Container>
  );
};

export default CalendarDiaryIntegration;