import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';
import moment from 'moment-timezone';

const getUserTimezone = () => {
  return localStorage.getItem('userTimezone') || 'Asia/Tokyo';
};

const getLocalDate = (date) => {
  const userTimezone = getUserTimezone();
  return moment.tz(date, userTimezone).format('YYYY-MM-DD');
};

export const generateStory = createAsyncThunk(
  'story/generate',
  async (date) => {
    const localDate = getLocalDate(date);
    const response = await axiosInstance.post(`/stories/generate/`, { date: localDate });
    return response.data;
  }
);

export const fetchStory = createAsyncThunk(
  'story/fetch',
  async (date) => {
    const localDate = getLocalDate(date);
    const response = await axiosInstance.get(`/stories/get_story_by_date/?date=${localDate}`);
    return response.data;
  }
);

export const fetchStories = createAsyncThunk(
  'story/fetchStories',
  async () => {
    const response = await axiosInstance.get('/stories/');
    return response.data;
  }
);

const storySlice = createSlice({
  name: 'story',
  initialState: {
    story: null,
    stories: [],
    exists: false,
    message: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateStory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(generateStory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.story = action.payload;
      })
      .addCase(generateStory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchStory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.exists = action.payload.exists;
        if (action.payload.exists) {
          state.story = action.payload.story;
          state.message = null;
        } else {
          state.story = null;
          state.message = action.payload.message;
        }
      })
      .addCase(fetchStory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchStories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stories = action.payload;
      })
      .addCase(fetchStories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default storySlice.reducer;