import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axiosInstance from '../api/axios';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CalendarSettings from './CalendarSettings';
import { Loader2 } from 'lucide-react'; // Loader2アイコンをインポート

const CalendarWrapper = styled.div`
  padding-left: 20px; // 左側に20pxの余白を追加
`;

const EventList = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
`;

const TimeSlot = styled.div`
  text-align: right;
  padding-right: 0.5rem;
  color: #666;
  font-weight: bold;
`;

const Event = styled.div`
  background-color: #e0e0ff;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  margin-bottom: 0.25rem;
`;

const CalendarSection = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 4px;
`;

const CalendarList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CalendarItem = styled.li`
  margin-bottom: 0.5rem;
`;

const SectionTitle = styled.h3`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
`;

const GoogleCalendarIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 0.5rem;
  object-fit: contain;
`;

const GoogleCalendarLink = styled.a`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: #4285F4;
  text-decoration: none;
  
  &:hover {
    opacity: 0.8;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const GoogleCalendar = () => {
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState({});
  const [events, setEvents] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [connectedCalendars, setConnectedCalendars] = useState([]);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const [availableCalendars, setAvailableCalendars] = useState([]);
  const [selectedConnectedCalendars, setSelectedConnectedCalendars] = useState({});
  const selectedDate = useSelector(state => state.date.selectedDate);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isCalendarSettingsOpen, setIsCalendarSettingsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const fetchEvents = useCallback(async (date) => {
    setIsLoading(true);
    try {
      const selectedDate = new Date(date);
      const yesterday = new Date(selectedDate);
      yesterday.setDate(selectedDate.getDate() - 1);
      const tomorrow = new Date(selectedDate);
      tomorrow.setDate(selectedDate.getDate() + 1);

      const response = await axiosInstance.get('/calendars/get_events/', {
        params: {
          timeMin: yesterday.toISOString(),
          timeMax: tomorrow.toISOString()
        }
      });
      console.log('Fetched events:', response.data);
      setEvents(response.data);
    } catch (error) {
      console.error('イベントの取得に失敗しました:', error);
      setError(error.response?.data?.error || `${t('eventFetchFailed')}: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [t, isInitialLoad]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && isConnected) {
        fetchEvents(selectedDate);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (isConnected && isInitialLoad) {
      fetchEvents(selectedDate);
      setIsInitialLoad(false);
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isConnected, selectedDate, fetchEvents, isInitialLoad]);

  const checkConnection = useCallback(async () => {
    try {
      await axiosInstance.get('/calendars/get_events/', {
        params: {
          timeMin: new Date().toISOString(),
          timeMax: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
        }
      });
      setIsConnected(true);
      setIsInitialLoad(true); // 接続が確認されたら初期ロードフラグをリセット
      const calendarResponse = await axiosInstance.get('/calendars/list_calendars/');
      console.log('Fetched calendars:', calendarResponse.data);
      setCalendars(calendarResponse.data);
    } catch (error) {
      console.error('カレンダー接続確認に失敗しました:', error);
      if (error.response && error.response.status === 401) {
        setIsConnected(false);
      } else {
        console.error('予期せぬエラーが発生しました:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      checkConnection();
      fetchCalendars();
      fetchConnectedCalendars();
    }
  }, [isAuthenticated, user, checkConnection]);

  useEffect(() => {
    // カレンダーリストから連携済のカレンダーを除外
    const filteredCalendars = calendars.filter(calendar => 
      !connectedCalendars.some(connectedCalendar => 
        connectedCalendar.google_calendar_id === calendar.id
      )
    );
    setAvailableCalendars(filteredCalendars);
  }, [calendars, connectedCalendars]);

  useEffect(() => {
    console.log('Connected calendars state:', connectedCalendars);
  }, [connectedCalendars]);

  useEffect(() => {
    if (isConnected) {
      fetchEvents(selectedDate);
    }
  }, [isConnected, selectedDate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authSuccess = urlParams.get('auth_success');
    const userEmail = urlParams.get('user_email');
    
    if (authSuccess === 'true' && userEmail) {
      // ユーザー情報を更新（Reduxストアを更新る処理を呼び出）
      // 例: dispatch(updateUser({ email: userEmail }));
      checkConnection();
    }
  }, []);

  const handleConnectGoogle = async () => {
    try {
      const response = await axiosInstance.get('/calendars/initiate_google_auth/');
      console.log('Authorization URL:', response.data.authorization_url);
      console.log('Redirect URI:', new URL(response.data.authorization_url).searchParams.get('redirect_uri'));  // この行を追加
      window.location.href = response.data.authorization_url;
    } catch (error) {
      console.error('Google認証の開始に失敗しました:', error);
    }
  };

  const handleCalendarSelection = (calendarId, isSelected) => {
    setSelectedCalendars(prev => ({ ...prev, [calendarId]: isSelected }));
  };

  const handleConnectCalendars = async () => {
    try {
      const selectedCalendarIds = Object.keys(selectedCalendars).filter(id => selectedCalendars[id]);
      const response = await axiosInstance.post('/calendars/connect_calendars/', { calendar_ids: selectedCalendarIds });
      console.log('カレンダーの連携が完了しました', response.data);
      
      // 連携したカレンダー情報を状態に保存
      setConnectedCalendars(prevConnectedCalendars => [...prevConnectedCalendars, ...response.data]);
      
      // 選択状態をリセット
      setSelectedCalendars({});
      
      // イベントと利用可能なカレンダーを再取得
      checkConnection();
      fetchCalendars();
      fetchConnectedCalendars();
    } catch (error) {
      console.error('カレンダーの連携に失敗しました:', error);
      setError(error.response?.data?.error || t('calendarConnectionFailed'));
    }
  };

  const fetchCalendars = async () => {
    try {
      const data = {};
      const response = await axiosInstance.post('/calendars/list_calendars/', data);
      setCalendars(response.data);
    } catch (error) {
      console.error('カレンダーリストの取得に失敗しました:', error);
      setError(error.response?.data?.error || t('calendarListFetchFailed'));
    }
  };

  const fetchConnectedCalendars = async () => {
    try {
      const response = await axiosInstance.get('/calendars/connected_calendars/');
      console.log('Connected calendars:', response.data);
      setConnectedCalendars(response.data);
    } catch (error) {
      console.error('連携済みカレンダの取得に失敗しました:', error);
      setError(error.response?.data?.error || t('connectedCalendarFetchFailed'));
    }
  };

  const handleConnectedCalendarSelection = (calendarId, isSelected) => {
    setSelectedConnectedCalendars(prev => ({ ...prev, [calendarId]: isSelected }));
  };

  const handleDisconnectCalendars = async () => {
    try {
      const selectedCalendarIds = Object.keys(selectedConnectedCalendars).filter(id => selectedConnectedCalendars[id]);
      const response = await axiosInstance.post('/calendars/disconnect_calendars/', { calendar_ids: selectedCalendarIds });
      console.log('カレンダーの連携解除が完了ました', response.data);
      
      // 連携解除したカレンーを状態から削除
      setConnectedCalendars(prevCalendars => prevCalendars.filter(calendar => !selectedCalendarIds.includes(calendar.id.toString())));
      
      // 選択状態をリセット
      setSelectedConnectedCalendars({});
      
      // イベントと利用可能なカレンダを再取得
      checkConnection();
      fetchCalendars();
      fetchConnectedCalendars();
    } catch (error) {
      console.error('カレンダーの連携解除に失敗しました:', error);
      setError(error.response?.data?.error || t('calendarDisconnectionFailed'));
    }
  };

  const handleOpenCalendarSettings = () => {
    setIsCalendarSettingsOpen(true);
  };

  const handleCloseCalendarSettings = () => {
    setIsCalendarSettingsOpen(false);
  };

  const renderEventsByDate = (events) => {
    console.log('Rendering events:', events);
    const selectedDateObj = new Date(selectedDate);
    selectedDateObj.setHours(0, 0, 0, 0);

    const filteredEvents = events.filter(event => {
      const eventDate = new Date(event.start.dateTime || event.start.date);
      eventDate.setHours(0, 0, 0, 0);
      return eventDate.getTime() === selectedDateObj.getTime();
    });

    const renderEventsForDay = (dayEvents) => {
      const timeSlots = [];
      for (let hour = 6; hour < 24; hour++) {
        const time = `${hour.toString().padStart(2, '0')}:00`;
        const eventsAtTime = dayEvents.filter(event => {
          const eventTime = new Date(event.start.dateTime || event.start.date);
          return eventTime.getHours() === hour;
        });

        timeSlots.push(
          <React.Fragment key={time}>
            <TimeSlot>{time}</TimeSlot>
            <div>
              {eventsAtTime.map((event, index) => (
                <Event key={index}>
                  {event.summary} ({event.calendar_name})
                </Event>
              ))}
            </div>
          </React.Fragment>
        );
      }
      return <EventList>{timeSlots}</EventList>;
    };

    return (
      <div>{renderEventsForDay(filteredEvents)}</div>
    );
  };

  const renderHeader = () => (
    <CardHeader className="h-16 flex items-center">
      <LabelWrapper className="w-full">
        <TitleButtonWrapper>
          <CardTitle className="text-2xl font-serif flex items-center">
            {t('schedule')}
            <GoogleCalendarLink href="https://calendar.google.com/" target="_blank" rel="noopener noreferrer" title="Googleカレンダーを開く">
              <GoogleCalendarIcon src="/images/google_calendar_icon.png" alt="Google Calendar" />
            </GoogleCalendarLink>
          </CardTitle>
          {!isConnected && (
            <Button onClick={handleConnectGoogle}>
              {t('connectGoogleCalendar')}
            </Button>
          )}
          {isConnected && (
            <Button onClick={handleOpenCalendarSettings}>
              {t('manageCalendars')}
            </Button>
          )}
        </TitleButtonWrapper>
        <ToggleButton onClick={toggleAccordion}>
          {isOpen ? '▲' : '▼'}
        </ToggleButton>
      </LabelWrapper>
    </CardHeader>
  );

  if (!isAuthenticated || !user) {
    return <div>{t('loginRequired')}</div>;
  }

  return (
    <CalendarWrapper>
      <Card className="h-full flex flex-col mt-2 relative"> {/* relative を追加 */}
        {renderHeader()}
        {isOpen && (
          <CardContent className="flex-grow overflow-auto">
            {error && <div className="error">{error}</div>}
            
            {events.length > 0 ? renderEventsByDate(events) : <p>{t('noEvents')}</p>}
            
          </CardContent>
        )}
        {isLoading && (
          <LoadingOverlay>
            <Loader2 className="h-8 w-8 animate-spin" />
          </LoadingOverlay>
        )}
      </Card>
      <CalendarSettings
        isOpen={isCalendarSettingsOpen}
        onClose={handleCloseCalendarSettings}
        connectedCalendars={connectedCalendars}
        availableCalendars={availableCalendars}
        onConnectCalendars={handleConnectCalendars}
        onDisconnectCalendars={handleDisconnectCalendars}
        handleCalendarSelection={handleCalendarSelection}
        handleConnectedCalendarSelection={handleConnectedCalendarSelection}
        selectedCalendars={selectedCalendars}
        selectedConnectedCalendars={selectedConnectedCalendars}
      />
    </CalendarWrapper>
  );
};

export default GoogleCalendar;