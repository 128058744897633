import { refreshToken, logout } from '../store/authSlice';

export const handleTokenRefresh = async (dispatch, originalRequest) => {
  try {
    const action = await dispatch(refreshToken());
    if (refreshToken.fulfilled.match(action)) {
      const newToken = action.payload.access;
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      return true;
    }
  } catch (refreshError) {
    dispatch(logout());
  }
  return false;
};