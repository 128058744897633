import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axios';
import { userSettingsFields, initialUserSettings } from '../constants/userSettingsFields';
import { useTranslation } from 'react-i18next';
import {
  Title,
  Form,
  FormGroup,
  Label,
  Input,
  Select,
  ButtonGroup,
  CancelButton,
  SaveButton,
  ErrorMessage,
  TabContainer,
  Tab
} from './common/FormStyles';
import { Textarea } from './ui/textarea';
import styled from 'styled-components';

const SettingsModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const SettingsModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 1001;
`;

const UserSettings = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const [userSettings, setUserSettings] = useState({
    gender: '',
    otherInfo: '',
    language: '',
    timezone: ''
  });
  const [storySettings, setStorySettings] = useState({
    littlePersonPronoun: '',
    masterTitle: '',
    littlePersonPersonality: '',
    storyTaste: ''
  });
  const [activeTab, setActiveTab] = useState('userSettings');

  useEffect(() => {
    if (isOpen) {
      fetchSettings();
    }
  }, [isOpen]);

  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get('/user-settings/');
      setUserSettings({
        gender: response.data.gender || '',
        otherInfo: response.data.other_info || '',
        language: response.data.language || 'ja',
        timezone: response.data.timezone || 'Asia/Tokyo'
      });
      setStorySettings({
        littlePersonPronoun: response.data.little_person_pronoun || '',
        masterTitle: response.data.master_title || '',
        littlePersonPersonality: response.data.little_person_personality || '',
        storyTaste: response.data.story_taste || ''
      });
    } catch (error) {
      console.error('設定の取得エラ:', error);
    }
  };

  const handleChange = (e, settingType) => {
    const { name, value } = e.target;
    if (settingType === 'user') {
      setUserSettings(prev => ({ ...prev, [name]: value }));
      if (name === 'language') {
        i18n.changeLanguage(value);
      }
    } else {
      setStorySettings(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put('/user-settings/update/', {
        ...userSettings,
        ...storySettings
      });
      onClose();
    } catch (error) {
      console.error('設定の保存エラー:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <SettingsModal>
      <SettingsModalContent>
        <Title>{t('userSettings.title')}</Title>
        <TabContainer>
          <Tab
            active={activeTab === 'userSettings'}
            onClick={() => setActiveTab('userSettings')}
          >
            {t('userSettings.title')}
          </Tab>
          <Tab
            active={activeTab === 'storySettings'}
            onClick={() => setActiveTab('storySettings')}
          >
            {t('storySettings.title')}
          </Tab>
        </TabContainer>
        {activeTab === 'userSettings' && (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="gender">{t('userSettings.gender')}</Label>
              <Select
                id="gender"
                name="gender"
                value={userSettings.gender}
                onChange={(e) => handleChange(e, 'user')}
              >
                <option value="preferNotToSay">{t('userSettings.gender.preferNotToSay')}</option>
                <option value="male">{t('userSettings.gender.male')}</option>
                <option value="female">{t('userSettings.gender.female')}</option>
                <option value="other">{t('userSettings.gender.other')}</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="otherInfo">{t('userSettings.otherInfo')}</Label>
              <Textarea
                id="otherInfo"
                name="otherInfo"
                value={userSettings.otherInfo}
                onChange={(e) => handleChange(e, 'user')}
                placeholder={t('userSettings.otherInfoPlaceholder')}
                maxLength={100}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="language">{t('userSettings.language')}</Label>
              <Select
                id="language"
                name="language"
                value={userSettings.language}
                onChange={(e) => handleChange(e, 'user')}
              >
                <option value="ja">{t('languages.japanese')}</option>
                <option value="en">{t('languages.english')}</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="timezone">{t('userSettings.timezone')}</Label>
              <Select
                id="timezone"
                name="timezone"
                value={userSettings.timezone}
                onChange={(e) => handleChange(e, 'user')}
              >
                <option value="Asia/Tokyo">{t('timezones.tokyo')}</option>
                <option value="America/New_York">{t('timezones.newYork')}</option>
              </Select>
            </FormGroup>
            <ButtonGroup>
              <CancelButton type="button" onClick={onClose}>
                {t('cancel')}
              </CancelButton>
              <SaveButton type="submit">
                {t('save')}
              </SaveButton>
            </ButtonGroup>
          </Form>
        )}
        {activeTab === 'storySettings' && (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="littlePersonPronoun">{t('storySettings.littlePersonPronoun')}</Label>
              <Input
                id="littlePersonPronoun"
                name="littlePersonPronoun"
                value={storySettings.littlePersonPronoun}
                onChange={(e) => handleChange(e, 'story')}
                maxLength={20}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="masterTitle">{t('storySettings.masterTitle')}</Label>
              <Input
                id="masterTitle"
                name="masterTitle"
                value={storySettings.masterTitle}
                onChange={(e) => handleChange(e, 'story')}
                maxLength={20}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="littlePersonPersonality">{t('storySettings.littlePersonPersonality')}</Label>
              <Input
                id="littlePersonPersonality"
                name="littlePersonPersonality"
                value={storySettings.littlePersonPersonality}
                onChange={(e) => handleChange(e, 'story')}
                maxLength={20}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="storyTaste">{t('storySettings.storyTaste')}</Label>
              <Input
                id="storyTaste"
                name="storyTaste"
                value={storySettings.storyTaste}
                onChange={(e) => handleChange(e, 'story')}
                maxLength={20}
              />
            </FormGroup>
            <ButtonGroup>
              <CancelButton type="button" onClick={onClose}>
                {t('cancel')}
              </CancelButton>
              <SaveButton type="submit">
                {t('save')}
              </SaveButton>
            </ButtonGroup>
          </Form>
        )}
      </SettingsModalContent>
    </SettingsModal>
  );
};

export default UserSettings;